<template>
    <div class="container">
        <Detail v-model:detailRow="detailRow" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <div class="tree-list">
                <a-input-search
                    v-model:value="keyWords"
                    placeholder="请输入关键词以检索"
                    enter-button
                    @search="handleSearchTree"
                />
                <a-tree
                    :tree-data="treeData"
                    :fieldNames="{
                        title: 'name',
                    }"
                    @select="handleSelectTree"
                >
                    <template #title="{ name, key, code }">
                        <span>【{{ code }}】{{ name }}</span>
                    </template>
                </a-tree>
            </div>
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1200 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailRow = record">详情</a>
                        </a-space>
                    </template>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import { confirmModal, showMessage } from "@/utils/common";
import {
    apiInventoryFileList,
    apiDataDictionaryEnable,
    apiDataDictionaryDelete,
    apiDataDictionaryMoveUp,
    apiDataDictionaryMoveDown,
    apiInventoryFileCategory,
} from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        Detail,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "存货档案编码",
                dataIndex: "code",
            },
            {
                title: "存货档案名称",
                dataIndex: "name",
            },
            {
                title: "规格型号",
                dataIndex: "spec",
            },
            {
                title: "备注",
                dataIndex: "remarks",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 80,
            },
        ];

        const state = reactive({
            detailRow: null,
            params: null,
            data: [],
            treeData: [],
            loading: false,
            keyWords: "",
            categoryCode: "",
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const getTreeData = async () => {
            let res = await apiInventoryFileCategory();
            if (res.status === "SUCCESS") {
                state.treeData = res?.data;
            }
        };
        getTreeData();

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiInventoryFileList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    categoryCode: state.categoryCode,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                const { totalRecord, recordList } = res?.data;
                state.pagination.total = totalRecord;
                state.data = recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const openUpDateModal = id => {
            state.upDateId = id;
        };

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "字典禁用后，之后将无法在业务流程中使用该字典信息，是否确认禁用？"
                : "字典启用后，该字典可以在业务流程中使用，是否确认启用？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiDataDictionaryEnable(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const deleteRow = id => {
            confirmModal({
                type: "warning",
                content: "字典项删除后将无法恢复，是否确认删除该字典项？",
                onOk: async () => {
                    let res = await apiDataDictionaryDelete([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const handleMoveUp = async id => {
            let res = await apiDataDictionaryMoveUp(id);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                getTabelData();
            }
        };

        const handleMoveDown = async id => {
            let res = await apiDataDictionaryMoveDown(id);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                getTabelData();
            }
        };

        const handleSearchTree = value => {
            if (!value) return getTreeData();
            let res = [];
            const cycleSearch = treeData => {
                for (let item of treeData) {
                    if (item.name === value) {
                        res = [item];
                        return res;
                    } else if (item?.children && item?.children?.length) {
                        res = cycleSearch(item?.children);
                        if (res !== undefined) {
                            return res;
                        }
                    }
                }
            };
            state.treeData = cycleSearch(state.treeData);
        };

        const handleSelectTree = (key, e) => {
            state.pagination.current = 1;
            state.categoryCode = e.selected ? e.node.code : "";
            getTabelData();
        };

        return {
            columns,
            ...toRefs(state),
            openUpDateModal,
            changeSwitch,
            deleteRow,
            search,
            paginationChange,
            getTabelData,
            handleMoveUp,
            handleMoveDown,
            handleSearchTree,
            handleSelectTree,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-collapse-header) {
    padding: 20px 0 0 !important;
}
:deep(.ant-collapse-content-box) {
    padding: 0;
    padding-top: 0 !important;
}
.ant-input-group-wrapper {
    margin-bottom: 10px;
}
.container {
    .type {
        margin: 10px 0;
        cursor: pointer;
        padding-left: 40px;
    }
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        .tree-list {
            width: 200px;
            margin-right: 10px;
        }
        .ant-table-wrapper {
            flex: 1;
            width: 0;
        }
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>

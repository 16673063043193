<template>
    <a-drawer
        v-model:visible="visible"
        :maskClosable="false"
        title="存货档案详情"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions title="" :column="2">
            <a-descriptions-item label="分类编码">
                {{ detailRow?.categoryCode }}
            </a-descriptions-item>
            <a-descriptions-item label="存货档案编码">
                {{ detailRow?.code }}
            </a-descriptions-item>
            <a-descriptions-item label="存货档案名称">
                {{ detailRow?.name }}
            </a-descriptions-item>
            <a-descriptions-item label="规格型号">
                {{ detailRow?.spec }}
            </a-descriptions-item>
            <a-descriptions-item label="助记码">
                {{ detailRow?.mnemonicCode }}
            </a-descriptions-item>
            <a-descriptions-item label="启用日期">
                {{ detailRow?.startDate }}
            </a-descriptions-item>
            <a-descriptions-item label="停用日期">
                {{ detailRow?.deactivateDate }}
            </a-descriptions-item>
            <a-descriptions-item label="供应商">
                {{ detailRow?.supplier }}
            </a-descriptions-item>
            <a-descriptions-item label="备注">
                {{ detailRow?.remarks }}
            </a-descriptions-item>
            <a-descriptions-item label="主计量单位">
                {{ detailRow?.primaryUom }}
            </a-descriptions-item>
            <a-descriptions-item label="建档人">
                {{ detailRow?.createBy }}
            </a-descriptions-item>
            <a-descriptions-item label="建档时间">
                {{ detailRow?.createTime }}
            </a-descriptions-item>
            <a-descriptions-item label="最后更新人">
                {{ detailRow?.lastUpdateBy }}
            </a-descriptions-item>
            <a-descriptions-item label="最后更新时间">
                {{ detailRow?.lastUpdateTime }}
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, ref, watch, reactive, toRefs } from "vue";

export default defineComponent({
    props: ["detailRow"],
    emits: ["update:detailRow"],
    setup(props, { emit }) {
        const state = reactive({
            loading: false,
            visible: false,
        });

        const handleCancel = () => {
            emit("update:detailRow");
        };

        watch(
            () => props.detailRow,
            newVal => {
                state.visible = Boolean(newVal);
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.title {
    margin-bottom: 10px;
}
.operate-content {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
